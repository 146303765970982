
































import { axiosConfig } from '@/api';
import { API } from '@/types';
import axios from 'axios';
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @Prop(Object) readonly modifyItem!: API.Images.GET | null; // Currently unused.
  attachedFile: File | null = null;
  uploading = false;

  cancel(): void {
    this.attachedFile = null;
    this.uploading = false;
    this.dialogProp = false;
  }

  async upload(): Promise<void> {
    if (!this.attachedFile) return;
    this.uploading = true;
    try {
      const formData = new FormData();
      formData.append('file', this.attachedFile);
      const { data } = await axios({
        ...axiosConfig(),
        method: this.modifyItem ? 'PUT' : 'POST',
        url: this.modifyItem ? `/images/${this.modifyItem.id}` : '/images',
        data: formData,
      });
      this.attachedFile = null;
      this.uploading = false;
      this.dialogProp = false;
      this.$emit('post', data);
    } catch (err) {
      // catch
    }
  }
}
