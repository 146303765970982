


















































import { API } from '@/types';
import { serverURL } from '@/util';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/components/Admin/DataTable.vue';
import Upload from './Upload.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  images: API.Images.GET[] = []; // Only stores the current page of images!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyImage: API.Images.GET | null = null;
  options = {
    apiName: 'images',
    socketName: 'imageModified',
    idStr: 'id',
    headers: [
      { text: 'Preview', value: 'preview' },
      { text: 'URL', value: 'url' },
      { text: 'Original Filename', value: 'originalFilename' },
      { text: 'Size', value: 'size' },
      { text: 'Created', value: 'creationTimestamp' },
      { text: 'Updated', value: 'updatedTimestamp' },
    ],
    modifyComponent: Upload,
  };
  serverURL = serverURL();
  dayjs = dayjs;
}
