var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"items":_vm.images,"tableOptions":_vm.tableOptions,"modifyItem":_vm.modifyImage,"options":_vm.options},on:{"update:items":function($event){_vm.images=$event},"update:tableOptions":function($event){_vm.tableOptions=$event},"update:table-options":function($event){_vm.tableOptions=$event},"update:modifyItem":function($event){_vm.modifyImage=$event},"update:modify-item":function($event){_vm.modifyImage=$event}},scopedSlots:_vm._u([{key:"deleteConfirmationTitle",fn:function(){return [_vm._v(" Image Delete Confirmation: "+_vm._s(_vm.modifyImage.originalFilename)+" ")]},proxy:true},{key:"deleteConfirmationText",fn:function(){return [_vm._v(" Are you sure you want to delete this image? ")]},proxy:true},{key:"addBtn",fn:function(){return [_vm._v("mdi-upload")]},proxy:true},{key:"addTooltip",fn:function(){return [_vm._v("Upload New Image")]},proxy:true},{key:"editBtn",fn:function(){return [_vm._v("mdi-upload")]},proxy:true},{key:"editTooltip",fn:function(){return [_vm._v("Upload Replacement")]},proxy:true},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('v-img',{key:item.md5,staticClass:"my-2",attrs:{"src":(_vm.serverURL + "assets/img/thumb/" + (item.filename) + ".webp?" + (item.md5)),"max-height":"150","max-width":"150"}})]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.serverURL + "assets/img/" + (item.filename) + "." + (item.extension)),"target":"_blank"}},[_vm._v(" Link ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.width)+"x"+_vm._s(item.height)+", "+_vm._s(Math.floor(item.size / 1024))+"KB ")]}},{key:"item.creationTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.creationTimestamp).format('MMMM D, YYYY h:mm A'))+" ")]}},{key:"item.updatedTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.updatedTimestamp).format('MMMM D, YYYY h:mm A'))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }